import { ref, watch, computed, onMounted } from '@vue/composition-api'
import store from '@/store'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function userGrowerLoanList() {
    const client = ref(JSON.parse(localStorage.getItem('userData')).client)

    const toast = useToast()
    const records = ref(null)

    const columns = ref([
        { key: 'season', searchable: false, },
        { key: 'number', label: 'Serial #', searchable: false, },
        { key: 'inputs', label: 'Items', sortable: false, thClass: 'text-center', tdClass: 'text-center' },
        'actions',
    ])

    const itemsColumns = ref([
        { key: 'name', label: 'Name', sortable: false, thStyle: { width: "40%" }, thClass: 'text-left', tdClass: 'text-left' },
        { key: 'quantity', label: 'Qty', sortable: false, thClass: 'text-right', tdClass: 'text-right' },
        { key: 'value', label: 'Value', sortable: false, thClass: 'text-right', tdClass: 'text-right' },
    ])

    const grower_id = ref(null)

    const from = ref(0)
    const to = ref(0)
    const meta = ref({ from: 0, to: 0, total: 0 })
    const perPage = ref(10)
    const totalRecords = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [5, 10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('updated_at')
    const isSortDirDesc = ref(true)

    const refetch = () => {
        records.value.refresh()
    }

    watch([currentPage, perPage, searchQuery, sortBy, isSortDirDesc], () => {
        refetch()
    })

    const fetch = (ctx, callback) => {
        store.dispatch('cromis-grower/loans', {
                debtor_id: grower_id.value,
                debtor_type: 1,
            }).then(response => {
                const { loans } = response.data
                meta.value = meta

                callback(loans)
                totalRecords.value = meta.value.total
                from.value = meta.value.from
                to.value = meta.value.to
            }).catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error fetching grower loans',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    // *===============================================---*
    // *--------- UI ---------------------------------------*
    // *===============================================---*

    const resolveGrowerTypeVariant = society => {
        if (society !== null) return 'success'
        return 'primary'
    }

    const resolveStatusVariant = status => {
        if (status === 1) return 'success'
        if (status === 0) return 'secondary'
        return 'warning'
    }

    return {
        fetch,
        columns,
        itemsColumns,
        perPage,
        meta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        records,
        currentPage,
        from,
        to,
        totalRecords,

        resolveGrowerTypeVariant,
        resolveStatusVariant,
        refetch,

        grower_id,
    }
}