<style>
.green-strip {
    border-left: 4px solid #28c76f;
    height: 10px !important;
    margin-bottom: 5px;
    padding: 4px;
    /* margin-left: -27px; */
}

.full-width-table {
    width: 100%;
}
</style>

<template>
    <div>
        <!-- Form Modal -->
        <b-modal id="modal-loan-form" ref="myModal" centered no-close-on-backdrop no-close-on-esc size="lg"
            @ok="submit">
            <!-- Custom Modal Header -->
            <template #modal-header="{ close }">
                <!-- Emulate built in modal header close button action -->
                <h5 v-if="loan.id === null || loan.id === 0">Issue Inputs</h5>
                <h5 v-else>Edit Issued Inputs of Serial # {{ loan.number }}</h5>
                <feather-icon class="ml-4 pr-0 cursor-pointer" icon="XIcon" size="16" @click="close()" />
            </template>

            <!-- Custom Modal Footer -->
            <template #modal-footer="{ ok, cancel }">
                <b-button size="sm" variant="primary" @click="ok()" :disabled="saving">
                    <b-spinner small class="mr-1" label="Small Spinner" v-if="saving" />
                    <span v-if="!saving">Save</span>
                    <span v-if="saving">Saving...</span>
                </b-button>
                <b-button size="sm" @click="cancel()" variant="outline-secondary">
                    Cancel
                </b-button>
            </template>

            <!-- Data Form in Modal Body -->
            <template #default="{ }">


                <b-row>

                    <!-- User Info: Left col -->
                    <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
                        <!-- User Avatar & Action Buttons -->
                        <div class="d-flex justify-content-start">

                            <b-avatar src="assets/images/avatars/0.jpg" size="130px" rounded />
                            <div class="d-flex flex-column ml-1">


                                <table class="mt-2 mt-xl-0 w-100">
                                    <tr>
                                        <th class="pb-50">
                                            <feather-icon icon="UserIcon" class="mr-75" />
                                            <span class="font-weight-bold">Name</span>
                                        </th>
                                        <td class="pb-50">
                                            {{ grower.person.firstName }} {{ grower.person.middleName }} {{
                                                grower.person.lastName }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>
                                            <feather-icon icon="PhoneIcon" class="mr-75" />
                                            <span class="font-weight-bold">Contact</span>
                                        </th>
                                        <td>
                                            {{ grower.mobile }}
                                        </td>
                                    </tr>
                                    <tr>
                                        <th class="pb-50">
                                            <feather-icon icon="StarIcon" class="mr-75" />
                                            <span class="font-weight-bold">NIN</span>
                                        </th>
                                        <td class="pb-50 text-capitalize">
                                            {{ grower.nin }}
                                        </td>
                                    </tr>

                                </table>

                            </div>
                        </div>

                        <!-- User Stats -->
                        <div class="d-flex align-items-center mt-2">
                            <div class="d-flex align-items-center mr-2">
                                <b-avatar variant="light-primary" rounded>
                                    <feather-icon icon="ColumnsIcon" size="18" />
                                </b-avatar>
                                <div class="ml-1">
                                    <h5 class="mb-0 text-center">
                                        {{ grower.contract.hectares }}
                                    </h5>
                                    <small>Hectors</small>
                                </div>
                            </div>

                            <div class="d-flex align-items-center">
                                <b-avatar variant="light-info" rounded>
                                    <feather-icon icon="CloudSnowIcon" size="18" />
                                </b-avatar>
                                <div class="ml-1">
                                    <h5 class="mb-0 text-center">
                                        <span v-if="grower.contract && grower.contract.motherBeds !== null">
                                            {{ grower.contract.motherBeds }}
                                        </span>
                                        <span v-else>0</span>
                                    </h5>
                                    <small>M/Beds</small>
                                </div>
                            </div>

                            <div class="ml-1 d-flex align-items-center">
                                <b-avatar variant="light-success" rounded>
                                    <feather-icon icon="CloudDrizzleIcon" size="18" />
                                </b-avatar>
                                <div class="ml-1">
                                    <h5 class="mb-0 text-center">
                                        0
                                    </h5>
                                    <small>S/Beds</small>
                                </div>
                            </div>
                        </div>
                    </b-col>

                    <!-- Right Col: Table -->
                    <b-col cols="12" xl="6">
                        <table class="mt-2 mt-xl-0 w-100">
                            <tr>
                                <th class="pb-50">
                                    <feather-icon icon="UserIcon" class="mr-75" />
                                    <span class="font-weight-bold">Farmer #</span>
                                </th>
                                <td class="pb-50">
                                    {{ grower.registrationNumber }}
                                </td>
                            </tr>
                            <tr>
                                <th class="pb-50">
                                    <feather-icon icon="CheckIcon" class="mr-75" />
                                    <span class="font-weight-bold">Status</span>
                                </th>
                                <td class="pb-50 text-capitalize">
                                    <span v-if="grower.isActive">Active</span>
                                    <span v-if="!grower.isActive">Not Active</span>
                                </td>
                            </tr>
                            <tr>
                                <th class="pb-50">
                                    <feather-icon icon="StarIcon" class="mr-75" />
                                    <span class="font-weight-bold">Role</span>
                                </th>
                                <td class="pb-50 text-capitalize">
                                    Farmer
                                </td>
                            </tr>
                            <tr>
                                <th class="pb-50">
                                    <feather-icon icon="MapIcon" class="mr-75" />
                                    <span class="font-weight-bold">{{ grower.location.level.name}}</span>
                                </th>
                                <td class="pb-50">
                                    {{ grower.location.name }}
                                </td>
                            </tr>
                            <tr>
                                <th>
                                    <feather-icon icon="HomeIcon" class="mr-75" />
                                    <span class="font-weight-bold">Village</span>
                                </th>
                                <td>
                                    {{ grower.village.name }}
                                </td>
                            </tr>
                        </table>
                    </b-col>
                </b-row>
                <hr />

                <validation-observer ref="dataForm" #default="{ invalid }">
                    <b-form ref="form" @submit.stop.prevent="submit">
                        <b-row>
                            <b-col cols="6">
                                <b-form-group label-for="number" label="Serial #">
                                    <validation-provider #default="{ errors }" name="number" rules="">
                                        <b-form-input
                                            type="text"
                                            id="number"
                                            name="number"
                                            v-model="loan.number"
                                            size="sm"
                                        />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small v-if="serverErrors && serverErrors.number" class="text-danger">{{ serverErrors.number[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <b-col cols="6">
                                <b-form-group label-for="dateTaken" label="Date Taken">
                                    <validation-provider #default="{ errors }" name="dateTaken" rules="">
                                        <b-form-datepicker id="dateTaken" name="dateTaken" v-model="loan.dateTaken"
                                            :state="errors.length > 0 ? false : null" size="sm"
                                            :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                                            locale="en" :max="new Date().toISOString().split('T')[0]" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                        <small class="text-danger" v-if="serverErrors && serverErrors.dateTaken">{{ serverErrors.dateTaken[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <!-- Inputs List -->
                        <b-row>
                            <b-col cols="12">
                                <b-table
                                    :items="loan.inputs"
                                    :fields="inputsColumns"
                                    small
                                    responsive="sm"
                                    sticky-header="240px"
                                >
                                <template #cell(prevQuantity)="data">
                                        <validation-provider #default="{ errors }" :name="'prev_' + data.index" rules="">
                                            <b-form-input
                                                :id="'prev_' + data.index"
                                                :name="'prev_' + data.index"
                                                size="sm"
                                                v-model="loan.inputs[data.index].prevQuantity"
                                                :state="errors.length > 0 ? false : null"
                                                type="number"
                                                style="text-align:right;"
                                                disabled
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.debtor_id">{{ serverErrors.debtor_id[0] }}</small>
                                        </validation-provider>
                                    </template>

                                    <template #cell(commulative)="data">
                                        <validation-provider #default="{ errors }" :name="'commulative_' + data.index" rules="">
                                            <b-form-input
                                                :id="'commulative_' + data.index"
                                                :name="'commulative_' + data.index"
                                                size="sm"
                                                v-model="loan.inputs[data.index].commulativeQuantity"
                                                :state="errors.length > 0 ? false : null"
                                                type="number"
                                                style="text-align:right;"
                                                disabled
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.debtor_id">{{ serverErrors.debtor_id[0] }}</small>
                                        </validation-provider>
                                    </template>

                                    <template #cell(quantity)="data">
                                        <validation-provider #default="{ errors }" :name="'quantity_' + data.index" rules="">
                                            <b-form-input
                                                :id="'quantity_' + data.index"
                                                :name="'quantity_' + data.index"
                                                size="sm"
                                                v-model="loan.inputs[data.index].quantity"
                                                :state="errors.length > 0 ? false : null"
                                                type="number"
                                                style="text-align:right;"
                                                @keyup="calculateCommulative(data.index)"
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.debtor_id">{{ serverErrors.debtor_id[0] }}</small>
                                        </validation-provider>
                                    </template>

                                    <template #cell(value)="data">
                                        <validation-provider #default="{ errors }" :name="'value_' + data.index" rules="">
                                            <b-form-input
                                                :id="'value_' + data.index"
                                                :name="'value_' + data.index"
                                                size="sm"
                                                v-model="loan.inputs[data.index].value"
                                                :state="errors.length > 0 ? false : null"
                                                type="number"
                                                style="text-align:right;"
                                                disabled
                                            />
                                            <small class="text-danger">{{ errors[0] }}</small>
                                            <small class="text-danger" v-if="serverErrors && serverErrors.debtor_id">{{ serverErrors.debtor_id[0] }}</small>
                                        </validation-provider>
                                    </template>
                                </b-table>
                            </b-col>
                        </b-row>
                    </b-form>
                </validation-observer>
            </template>
        </b-modal>
        <!-- ./Form Modal -->

        <!-- Table Container Card -->
        <b-card no-body class="mb-0">
            <div class="m-2">
                <!-- Table Top -->
                <b-row>
                    <!-- Per Page -->
                    <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50 select-size-sm"
                        />
                        <label>entries</label>
                    </b-col>
                    <!-- ./Per Page -->

                    <!-- Search & Button -->
                    <b-col cols="12" md="6">
                        <div class="d-flex align-items-center justify-content-end">
                            <b-form-input
                                v-model="searchQuery"
                                class="d-inline-block mr-1"
                                placeholder="Search..."
                                size="sm"
                            />
                            <b-button variant="primary" size="sm" @click="invokeCreateForm()">
                                <span class="text-nowrap">Issue Input</span>
                            </b-button>
                        </div>
                    </b-col>
                    <!-- ./Search & Button-->
                </b-row>
                <!-- ./Table Top -->
            </div>

            <!-- Table -->
            <b-table
                striped
                hover
                small
                ref="records"
                class="position-relative"
                :items="fetch"
                responsive
                :fields="columns"
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No records found"
                :sort-desc.sync="isSortDirDesc"
            >

                <template #cell(season)="data">
                    <span class="green-strip"></span>
                    <span>{{ data.item.season.startYear }} / {{ data.item.season.endYear }} </span>
                </template>

                <template #cell(inputs)="data">
                    <b-table
                        striped
                        hover
                        small
                        class="position-relative"
                        :items="data.item.inputs"
                        responsive
                        :fields="itemsColumns"
                        primary-key="id"
                        :sort-by.sync="sortBy"
                        show-empty
                        empty-text="No records found"
                        :sort-desc.sync="isSortDirDesc"
                        sticky-header="240px"
                    >
                        <template #cell(quantity)="data">
                            <span v-if="data.item.prevQuantity > 1">
                                {{ data.item.prevQuantity.toLocaleString() }} {{ data.item.unit }}s
                            </span>
                            <span v-else>
                                {{ data.item.prevQuantity.toLocaleString() }} {{ data.item.unit }}
                            </span>
                        </template>

                        <template #cell(value)="data">
                            <span v-if="data.item.price !== null">
                                {{ (data.item.price.value *  data.item.prevQuantity).toLocaleString() }}}
                            </span>
                            <span v-else>
                                {{ (1 *  data.item.prevQuantity).toLocaleString() }}
                            </span>
                        </template>
                    </b-table>
                </template>

                <!-- Column: Actions -->
                <template #cell(actions)="data">
                    <b-dropdown variant="link" no-caret :right="$store.state.appConfig.isRTL">

                        <template #button-content>
                            <feather-icon icon="MoreVerticalIcon" size="16" class="align-middle text-body" />
                        </template>

                        <b-dropdown-item @click="invokeUpdateForm(data.item)">
                            <feather-icon icon="EditIcon" />
                            <span class="align-middle ml-50">Edit</span>
                        </b-dropdown-item>

                        <b-dropdown-item @click="confirmDelete(data.item.id)">
                            <feather-icon icon="TrashIcon" />
                            <span class="align-middle ml-50">Delete</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </template>
            </b-table>

            <!-- Table Footer -->
            <div class="mx-2 mb-2">
                <b-row>
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start">
                        <span class="text-muted">Showing {{ from }} to {{ to }} of {{ totalRecords }} entries</span>
                    </b-col>
                    <!-- Pagination -->
                    <b-col cols="12" sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end">
                        <b-pagination v-model="currentPage" :total-rows="totalRecords" :per-page="perPage" first-number
                            last-number class="mb-0 mt-1 mt-sm-0" prev-class="prev-item" next-class="next-item">
                            <template #prev-text>
                                <feather-icon icon="ChevronLeftIcon" size="18" />
                            </template>
                            <template #next-text>
                                <feather-icon icon="ChevronRightIcon" size="18" />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>
        <!-- ./Table Container Card -->
    </div>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
    BRow, BCol, BModal, VBModal, BButton, BTable,
    BBadge, BDropdown, BDropdownItem, BPagination, BSpinner, BMedia, BAvatar, BLink,
    BForm, BFormGroup, BFormSelect, BFormSelectOption, BFormInput,
    BFormCheckbox,
    BCard, BCardHeader, BCardSubTitle, BCardBody, BFormDatepicker,
} from 'bootstrap-vue'
import { required, integer } from '@validations'
import store from '@/store'
import { ref, onUnmounted, onMounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import vSelect from 'vue-select'
import useGrowerLoanList from '@/views/cromis/grower/useGrowerLoanList'
import growersStoreModule from '@/views/cromis/grower/growersStoreModule'
import { useToast } from 'vue-toastification/composition'
import moment from 'moment'


export default {
    props: {
        growerId: {
            type: Number,
            required: true,
        },
    },

    components: {
        BCard,
        BCardHeader,
        BCardSubTitle,
        BCardBody,
        BRow,
        BCol,
        BForm,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BFormSelectOption,
        BFormCheckbox,
        BFormDatepicker,
        BModal,
        BButton,
        BSpinner,
        BMedia,
        BLink,
        BAvatar,
        BTable,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,

        vSelect,
        ValidationProvider,
        ValidationObserver,
    },
    directives: {
        'b-modal': VBModal,
    },

    setup(props, context) {
        const saving = ref(false)
        const serverErrors = ref(null)
        const dataForm = ref(null);
        const myModal = ref(null)

        const packages = ref([])
        const inputs = ref([])
        const loans = ref([])
        const grower = ref([])
        const season_id = ref(0)

        const errosOcurs = ref(null)


        const inputsColumns = ref([
            { key: 'name', label: 'Item', sortable: false, thStyle: { width: "28%" }},
            { key: 'unit', label: 'Unit', sortable: false, thStyle: { width: "5%" }},
            { key: 'prevQuantity', label: 'Prev/Qty', sortable: false , thStyle: { width: "12%" }, thClass: 'text-right', tdClass: 'text-right'},
            { key: 'quantity', label: 'Qty', sortable: false, thClass: 'text-right', tdClass: 'text-right' },
            { key: 'commulative', label: 'Cumml/Qty', sortable: false , thStyle: { width: "18%" }, thClass: 'text-right', tdClass: 'text-right'},
            { key: 'value', label: 'Value', sortable: false , thStyle: { width: "22%" }, thClass: 'text-right', tdClass: 'text-right'},
        ])

        const loan = ref({
            id: null,
            season_id: null,
            debtor_type: 1,
            debtor_id: props.growerId,
            number: null,
            inputs: [],
        })

        const CROMIS_GROWER_STORE_MODULE_NAME = 'cromis-grower'

        // Register module
        if (!store.hasModule(CROMIS_GROWER_STORE_MODULE_NAME)) store.registerModule(CROMIS_GROWER_STORE_MODULE_NAME, growersStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(CROMIS_GROWER_STORE_MODULE_NAME)) store.unregisterModule(CROMIS_GROWER_STORE_MODULE_NAME)
        })

        onMounted(async () => {
            await store.dispatch('cromis-grower/show', { id: props.growerId })
                .then(response => {
                    grower.value = response.data

                    let found = false
                    grower.value.loans.forEach((loaned) => {
                        loaned.inputs.forEach((input) => {
                            found = false

                            for(let i = 0; i < loan.value.inputs.length; i++){
                                if(loan.value.inputs[i].id == input.id){
                                    loan.value.inputs[i].prevQuantity += input.prevQuantity
                                    found = true
                                }
                            }

                            if(!found){
                                loan.value.inputs.push(input)
                            }
                        })
                    })
                })
                .catch(error => {
                    console.log(error)
                })

            await store.dispatch('cromis-grower/packages')
                .then(response => {
                    packages.value = response.data.packages
                })
                .catch(error => {
                    console.log('got error', error)
                })

            await store.dispatch('cromis-grower/inputs')
                .then(response => {
                    inputs.value = response.data.inputs

                    inputs.value.forEach((input, index) => {
                        let found = false

                        for(let i = 0; i < loan.value.inputs.length; i++) {
                            if (loan.value.inputs[i].id == input.id) {
                                found = true
                            }
                        }

                        if(!found) {
                            loan.value.inputs.push({
                                id: input.id,
                                name: input.name,
                                unit: input.unit,
                                price: input.price,
                                prevQuantity: 0,
                                commulativeQuantity: 0,
                                quantity: null,
                                value: null,
                            })
                        }
                    });

                    loan.value.inputs.forEach((input, index) => {
                        calculateCommulative(index)
                    })
                })
                .catch(error => {
                    console.log('got error', error);
                });


            await store.dispatch('cromis-grower/seasons')
                .then(response => {
                    response.data.seasons.forEach(el => {
                        if (el.isRunning == true) {
                            loan.value.season_id = el.id
                            season_id.value = el.id
                        }
                    });
                })
                .catch(error => {
                    console.log('got error', error)
                })
        })

        const calculateCommulative = (index) => {
            if(loan.value.inputs[index].quantity === undefined || loan.value.inputs[index].quantity === null || loan.value.inputs[index].quantity === NaN || loan.value.inputs[index].quantity === '') {
                loan.value.inputs[index].commulativeQuantity = loan.value.inputs[index].prevQuantity
            }
            else{
                loan.value.inputs[index].commulativeQuantity = loan.value.inputs[index].prevQuantity + parseInt(loan.value.inputs[index].quantity)
            }
        }

        const {
            fetch,
            refetch,
            columns,
            itemsColumns,
            perPage,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            currentPage,
            from,
            to,
            totalRecords,

            // resolveUserRoleIcon,
            resolveGrowerTypeVariant,
            resolveStatusVariant,

            // Filers
            grower_id,
        } = useGrowerLoanList()

        grower_id.value = props.growerId


        // Methods
        const invokeUpdateForm = (item) => {
            loan.value.inputs.splice(0)

            loan.value.id = item.id
            loan.value.season_id = item.season.id
            loan.value.number = item.number

            let found = false
            item.inputs.forEach((input) => {
                found = false

                for(let i = 0; i < loan.value.inputs.length; i++){
                    if(loan.value.inputs[i].id == input.id){
                        loan.value.inputs[i].prevQuantity += input.prevQuantity
                        loan.value.inputs[i].quantity = input.prevQuantity
                        found = true
                    }
                }

                if(!found){
                    input.quantity = input.prevQuantity
                    loan.value.inputs.push(input)
                }
            })

            inputs.value.forEach((input, index) => {
                let found = false

                for(let i = 0; i < loan.value.inputs.length; i++) {
                    if (loan.value.inputs[i].id == input.id) {
                        found = true
                    }
                }

                if(!found) {
                    loan.value.inputs.push({
                        id: input.id,
                        name: input.name,
                        unit: input.unit,
                        price: input.price,
                        prevQuantity: 0,
                        commulativeQuantity: 0,
                        quantity: null,
                        value: null,
                    })
                }
            });

            loan.value.inputs.forEach((input, index) => {
                calculateCommulative(index)
            })

            myModal.value.show()
        }

        const invokeCreateForm = () => {
            loan.value.id = null
            loan.value.season_id = null
            loan.value.number = null
            loan.value.dateTaken = null

            loan.value.inputs.splice(0)

            let found = false
            grower.value.loans.forEach((loaned) => {
                loaned.inputs.forEach((input) => {
                    found = false

                    for(let i = 0; i < loan.value.inputs.length; i++){
                        if(loan.value.inputs[i].id == input.id){
                            loan.value.inputs[i].prevQuantity += input.prevQuantity
                            found = true
                        }
                    }

                    if(!found){
                        loan.value.inputs.push(input)
                    }
                })
            })

            inputs.value.forEach((input, index) => {
                let found = false

                for(let i = 0; i < loan.value.inputs.length; i++) {
                    if (loan.value.inputs[i].id == input.id) {
                        found = true
                    }
                }

                if(!found) {
                    loan.value.inputs.push({
                        id: input.id,
                        name: input.name,
                        unit: input.unit,
                        price: input.price,
                        prevQuantity: 0,
                        commulativeQuantity: 0,
                        quantity: null,
                        value: null,
                    })
                }
            });

            loan.value.inputs.forEach((input, index) => {
                calculateCommulative(index)
            })

            myModal.value.show()
        }

        const invokeIssueInput = () => {
            myModal.value.show()
        }

        const isFormValid = async () => {
            let isValid = false
            await dataForm.value.validate().then(success => {
                isValid = success
            })

            return isValid
        }

        const submit = (bvModalEvt) => {
            bvModalEvt.preventDefault()

            serverErrors.value = null
            // Handle form submit

            let item = loan.value

            for(let i = 0; i < item.inputs.length; i++){
                item.inputs[i] = {
                    id: item.inputs[i].id,
                    package_id: 1,
                    quantity: item.inputs[i].quantity,
                }
            }

            if (item.id === null || item.id === 0)
                handleCreate()
            else
                handleUpdate(item)
        }

        const handleCreate = async () => {
            saving.value = true

            loan.value.season_id = season_id.value
            loan.value.debtor_id = grower.value.id

            await store.dispatch('cromis-grower/loanSave', loan.value).then(response => {
                refetch()
                saving.value = false

                myModal.value.hide()

                context.root.$swal({
                    icon: 'success',
                    text: `Farmer's loan has been saved with successfully!`,
                    showConfirmButton: true,
                    timer: 2000,
                    customClass: {
                        confirmButton: 'btn btn-primary',
                    },
                    buttonsStyling: false,
                })
            })
                .catch(error => {
                    saving.value = false
                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors
                    }
                    else {
                        console.log(error.responce)
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const getPackage = async () => {

        }

        const handleUpdate = async (item) => {
            saving.value = true
            const valid = await isFormValid()

            if (!valid) {
                saving.value = false
                return
            }

            await store.dispatch('cromis-grower/loanUpdate', { id: item.id, data: item }).then(response => {

                refetch()
                saving.value = false

                myModal.value.hide()

                context.root.$swal({
                    icon: 'success',
                    text: `Changes to farmer's has been saved successfully!`,
                    showConfirmButton: false,
                    timer: 2000,
                    customClass: {
                        confirmButton: 'btn btn-success',
                    },
                    buttonsStyling: false,
                })
            })
                .catch(error => {
                    saving.value = false

                    console.log(error)

                    if (error.response.status === 422) {
                        serverErrors.value = error.response.data.errors;
                    } else {
                        context.root.$swal({
                            icon: 'error',
                            title: 'Server Error',
                            text: 'Something went wrong. See tech support',
                            showConfirmButton: true,
                            customClass: {
                                confirmButton: 'btn btn-danger',
                            },
                            buttonsStyling: false,
                        })
                    }
                })
        }

        const remove = async (id) => {
            await store.dispatch('cromis-grower/remove', id).then(response => {
                refetch()
            }).catch(error => {
                context.root.$swal({
                    icon: 'error',
                    title: 'Server Error',
                    text: 'Something went wrong. See tech support',
                    showConfirmButton: false,
                    customClass: {
                        confirmButton: 'btn btn-danger',
                    },
                    buttonsStyling: false,
                })
            })
        }

        return {
            fetch,
            refetch,
            columns,
            itemsColumns,
            inputsColumns,
            meta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            records,
            perPage,
            currentPage,
            from,
            to,
            totalRecords,
            myModal,
            dataForm,
            errosOcurs,
            // UI
            // resolveUserRoleVariant,
            // resolveUserRoleIcon,
            resolveGrowerTypeVariant,
            resolveStatusVariant,

            // Validations
            required,
            integer,

            // Methods and Form refs
            saving,
            serverErrors,
            invokeCreateForm,
            // invokeCreateFormInput,
            invokeUpdateForm,
            isFormValid,
            submit,
            handleCreate,
            handleUpdate,
            remove,
            packages,
            loan,
            getPackage,
            inputs,
            loans,
            grower,
            invokeIssueInput,
            calculateCommulative,
        }
    }
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';
</style>
